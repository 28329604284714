@charset "utf-8";

/*!===========================
このcssはSassから生成されていますので、
直接編集しないようご注意ください。
===========================*/

@media (max-width: 767px) {
html {
	font-size: 43.75%;/*70%*/
}
table {
	> tbody {
		> tr {
			> th,
			> td {
				padding: 10px;
			}
		}
	}
}
/* class
============================== */
.pc {
	display: none;
}
.sp {
	display: block;
}
img.sp,
span.sp {
	display: inline;
}
table.sp {
	display: table;
}
%clearfix::after {
	content: "";
	display: block;
	clear: both;
}
.alignleft,
.alignright {
	float: none;
	max-width: 100% !important;
}
.alignleft {
	margin-right: 0;
}
.alignright {
	margin-left: 0;
}
.col {
	display: block;
	> * {
		width: auto !important;
		max-width: 100% !important;
		margin-left: 0 !important;
		&:nth-child(n+2) {
			margin-top: 20px !important;
		}
	}
	> table {
		width: 100% !important;
	}
}
.col figure {
	> *:not(p) {
		display: block;
		margin-left: auto !important;
		margin-right: auto !important;
	}
}
.scroll {
	overflow-x: auto;
	&::-webkit-scrollbar {
		height: 5px;
	}
	&::-webkit-scrollbar-track,
	&::-webkit-scrollbar-thumb {
		border-radius: 5px;
	}
	&::-webkit-scrollbar-track {
		background: #CCCCCC;
	}
	&::-webkit-scrollbar-thumb {
		background: #666666;
	}
	table {
		white-space: nowrap;
	}
}
.btn {
	max-width: 230px !important;
	font-size: calc(1.15rem / .7) !important;
	padding-top: .8em !important;
	padding-bottom: .8em !important;
}
/* ==================================================
header
================================================== */
header {
  padding: 10px 60px 10px 20px;
}
.drawer-hamburger {
	position: absolute;
	width: 27px;
	height: 38px;
	text-indent: 120%;
	white-space: nowrap;
	overflow: hidden;
	margin: 10px 15px 0 0;
	padding: 0;
	background: url("../img/sp-menu.svg") center no-repeat;
	span {
		display: none;
	}
	&-icon {
		margin-top: 0;
	}
	.drawer--right.drawer-open & {
		right: 200px;
		background: none;
		span {
			display: block;
		}
	}
}
#hlogo-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
#hlogo {
	padding-left: 0;
	width: 100px;
}
#hnav {
	position: static;
	li {
		width: auto;
		margin-left: 0 !important;
	}
	a {
		display: block;
		width: 40px;
		height: 40px;
		text-indent: 120%;
		white-space: nowrap;
		overflow: hidden;
		padding: 0;
		background: center no-repeat !important;
		border: none;
		box-shadow: none;
		@for $no from 1 through 2 {
			&.no0#{$no} {
				background-image: url("../img/hnav0#{$no}-sp.svg") !important;
			}
		}
		&::before {
			content: normal;
		}
	}
}
#gnav-wrap {
	width: 200px !important;
	padding: 25px 20px;
}
.drawer--right .drawer-nav {
	right: -200px;
}
#gnav {
	display: block;
	margin-top: 0;
	padding-left: 0;
	li {
		margin-bottom: 1.8em;
	}
}
/* ==================================================
footer
================================================== */
#fnav {
	li {
		display: block;
		font-size: calc(1.2rem / .7);
		margin: 0 0 1em;
	}
}
/* ==================================================
main
================================================== */
#pttl {
  height: 150px;
}
#fbnr {
	padding-top: 40px !important;
	background: #F6F5F3 !important;
	> * {
		@extend .col;
		background: none !important;
		> figure {
			width: 80% !important;
			max-width: 360px !important;
			margin: 0 auto !important;
		}
		> div {
			padding: 0;
		}
		.btn {
			margin-right: auto;
		}
	}
}
#content {
	> * {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
body:not(#top) #content {
	> * > * {
		&:nth-child(n+2) {
			margin-top: 30px;
		}
	}
	h2 {
		+ * {
			margin-top: 30px !important;
		}
	}
}
/* top
============================== */
#top-nav {
	margin-top: -40px;
	padding-top: 80px;
	ul {
		@extend .col;
		margin: 0 -20px;
		li:nth-child(n) {
			margin-top: 0 !important;
		}
		a {
			padding: 30px 20px;
		}
	}
}
#top-news {
	ul {
		margin-left: -20px;
		margin-right: -20px;
		border-bottom: 1px solid #F6F5F3;
		li {
			@extend %clearfix;
			margin-top: 0;
			border-top: 1px solid #F6F5F3;
		}
		a {
			display: block;
			padding: 10px;
		}
		.cate {
			float: left;
			width: 8em;
			color: #fff;
			padding: 1em;
		}
		.date,
		.txt {
			width: calc(100% - 9em);
			margin-left: 9em;
			padding: 0;
			background: none;
		}
		.date {
			text-align: left;
		}
	}
}
	> p {
		margin-top: 20px;
	}

/* news
============================== */
#news-idx {
	@extend #top-news;
}
/* sample
============================== */
#sample-sec01 {
	.item601 {
		border: #a72126 1px solid;
		padding: 1.5em 1.0em;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 600px;
		figure {
			width: 100%;
			img {
				max-width: 160px !important;
			}
		}
		.txt {
			width: 100%;
			text-align: center;
			p {
				font-size: 18px;
				margin-bottom: 10px;
			}
			.btn {
				background-color: #a72126;
				color: #fff;
				margin: 0 auto;
				max-width: 250px;
			}
		}
	}
	.sample-cnt01 {
		ul {
			li {
				max-width: none;
				margin: 0 0 15px;
			}
		}
	}
	.sample-cnt02 {
		ul {
			li {
				width: 100%;
				margin-bottom: 20px;
				&:last-child {
					width: 100%;
					margin-bottom: 0;
				}
			}
		}
	}
	.sample-cnt03 {
		.sample-cnt03-txt {
			ul {
				padding: 0px 10px 20px;
				li {
					width: 100%;
					margin-bottom: 20px;
				}
			}
		}
	}
}
/* map
============================== */
  .map-idx dl dt {
    width: 100%;
  }
  .map-idx dl dd {
    padding-left: 30px;
  }

}
